<template>
  <div>
    <div class="Header"><Header/></div>
    <van-search
  v-model="value"
  show-action
  placeholder="请输入您想查询的申述名"
  @search="getShenshu()"
>
<template #action>
    <div @click="getShenshu()">搜索</div>
  </template>
</van-search>

<van-swipe-cell  v-for="v in mlist" :key="v.shenshuid">
  <template #left>
    <van-button square type="primary" @click="ck(v.shenshuid)" text="查看" />
  </template>
<h5 class="r">{{v.shenshu}}</h5>
  <template #right>
    <!-- <van-button square type="danger" @click="shanchu(v.shenshuid)" text="删除" /> -->
    <van-button square type="primary" @click="wc(v.shenshuid,v.orderid)" text="完成" />
  </template>
</van-swipe-cell>
                  <!-- <tr>
                    <th>1</th>
                    <th>2</th>
                    <th>3</th>
                    <th>4</th>
                  </tr>
                  <tr v-for="v in mlist.data" :key="v.shenshuid">
                    <td>    {{v.shenshu}}</td>
                    <td>                 <button @click="ck(v.shenshuid)">查看</button></td>
                    <td>       <button @click="wc(v.shenshuid)">完成</button></td>
                    <td>                <button @click="shan(v.shenshuid)">删除</button></td>
                  </tr> -->
  <div class="pagination-container">  
          <van-pagination
      @click="getShenshu()"
  v-model="currentPage"
  :total-items="countShenshu"
  :show-page-size="3"/>
  </div>


<div class="Footer"><AMFooter/></div>

  </div>
</template>

<script>
import Header from '@/components/Header.vue';
import AMFooter from '@/components/AMFooter.vue';
import { ElMessage } from "element-plus";
import { ref } from "vue";
  export default {

setup() {
    const currentPage = ref(1);
    return { currentPage };
  },

    data(){
      return{
        username:"",
          mlist:[],
          value:"",
          countShenshu:0,
      }
    },
        mounted() {
          console.log("username:"+sessionStorage.getItem("name"));
          this.username=sessionStorage.getItem("name");
          this.getShenshu();
          
          this.axios({
      method:"get",
      url:"/api/shenshu/getShenshuCount",
    })
    .then((response)=>{
      console.log(response.data.data);
      this.countShenshu=response.data.data;
      console.log(this.countShenshu);
    })
        
    
  },
  methods:{
    getShenshu(){
        //拿申述
                const dqy=(this.currentPage-1)*10;
      const gs=10;
  console.log("页数："+this.currentPage);
    this.axios({
              method: "get",
              url:"/api/shenshu/pagename",
              params:{
                pageNum:dqy,
                pageSize:gs,
                shenshu:this.value,

              }
             
            })
            .then(
                (response)=>{                  
                    this.mlist = response.data.data;
                    console.log(this.mlist);
                      if(this.mlist[0]==undefined){ 
                    ElMessage("没有这么多申诉啦！！");

      }
                })
                },
            ck(sid){
               this.$router.push({name:"AdminMainDetel",params:{shenshuid:sid}})
            },
            wc(sid,orderid){
              //将申述删除，将order状态设置为：2  已完成
              this.axios({
              method: "post",
              url:"/api/shenshu/delete",
              params:{
                shenshuid:sid,
              }
             
            })
            console.log(orderid);
                  this.gx(orderid);
    
            },
            gx(orderid){
                    this.axios({
                    method:"post",
                    url:"/api/order/update",
                    params:{
                      orderStatus:2,
                      orderid:orderid,
                    }
                  }).then(
                ()=>{ 
                  ElMessage("订单完成！");
                  this.getShenshu();
                }).catch(
                  ()=>{
                    ElMessage("订单完成失败！");
                })
            }
           


  },

    components: { AMFooter,Header },
  }
</script>

<style lang="scss" scoped>
.r{
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  text-align: center;

}
::v-deep   .van-button{
margin-top: -10px;
}
.pagination-container {  
  position: fixed;  
  bottom: 50px;  
  left: 0;  
  width: 100%;  
  background-color: #fff; 
  text-align: center; 
  z-index: 9999; 
  box-shadow: 0 -1px 5px rgba(0, 0, 0, 0.1); 
}
</style>