<template>
  <div>
    <Header/>
    <div class="kk">
      <!-- 文件上传 -->
      <el-upload action="/api/upload/img"
        :show-file-list="true"
        :on-success="handleFileUploadSuccess"
        name="tupian"
        ref="aupload"
        :limit="1"
        style="display: inline-block">
        <el-button type="primary" class="ml-5">
          上传图片 
        </el-button>
      </el-upload>
      <el-button type="primary" @click="clear()" style="margin-left: 20px;" class="ml-5">
        删除图片
      </el-button>

      <van-cell-group inset>
        <van-field placeholder="例如：买西瓜" v-model="order.orderName" label="任务名称：" />
        <van-field
          style="text-align: center;"
          v-model="fieldText"
          is-link
          readonly
          placeholder="选择任务类型："
          @click="showPicker = true"
        />
        <van-popup v-model:show="showPicker" round position="bottom">
          <van-picker
            :columns="columns"
            @cancel="showPicker = false"
            @confirm="onConfirm"
          />
        </van-popup>
        
        <van-field
          v-model="order.detailAddress"
          rows="2"
          autosize
          label="需求："
          type="textarea"
          maxlength="50"
          placeholder="请写清自己的需求，以免无人接单 例如：去什么地方干什么"
          show-word-limit
        />

        <!-- 输入手机号，调起手机号键盘 -->
        <van-field v-model="order.dh" placeholder="例如：18878950480" type="digit" label="电话：" />

        <van-field
          v-model="fieldValueAdd"
          is-link
          readonly
          label="地区："
          placeholder="请选择所在地区"
          @click="showAdd = true"
        />
        <van-popup v-model:show="showAdd" round position="bottom">
          <van-cascader
            v-model="cascaderValueAdd"
            title="请选择所在地区"
            :options="options"
            @close="showAdd = false"
            @finish="onFinish"
          />
        </van-popup>
        
        <!-- 输入任意文本 -->
        <van-field v-model="order.buyAddress" placeholder="详细任务地址" label="详细地址：" />
        <!-- 允许输入数字，调起带符号的纯数字键盘 -->
        <van-field v-model="order.money" type="digit" placeholder="任务所需金额，可填写无" label="预计金额：" />
        <van-field v-model="order.orderCreateTime" placeholder="例如：4月18号下午三点" label="开始时间：" />
        <!-- <div class="block">
            <span class="demonstration">开始时间：</span>
            <el-date-picker
              v-model="order.orderCreateTime"
              type="datetime"
              placeholder="选择日期时间">
            </el-date-picker>
        </div> -->
        <van-field v-model="order.price" type="digit" placeholder="您将要打赏的金额" label="赏金：" />
      </van-cell-group>

      <van-button class="fb" round type="primary" @click="fb">发布</van-button>
    </div>
    <Footer/>
  </div>
</template>

<script>
import { ref } from 'vue';
import { ElMessage } from "element-plus";
import { useCascaderAreaData } from '@vant/area-data';
import Footer from '@/components/Footer.vue';
import Header from '@/components/Header.vue';


export default {
  components: { Footer, Header},
  setup() {
    const columns = [
      { text: '代购物品', value: 1 },
      { text: '专业陪伴', value: 2 },
      { text: '宠物代看', value: 0 },
      { text: '其他', value: 3 },
    ];
    const fieldValue = ref('');
    const fieldText = ref('');
    const showPicker = ref(false);
    const showAdd = ref(false);
    const fieldValueAdd = ref('');
    const cascaderValueAdd = ref('');


    const options = useCascaderAreaData();
    const onFinish = ({ selectedOptions }) => {
      showAdd.value = false;
      fieldValueAdd.value = selectedOptions.map((option) => option.text).join('/');
    };

    const onConfirm = ({ selectedOptions }) => {
      showPicker.value = false;
      fieldValue.value = selectedOptions[0].value;
      fieldText.value = selectedOptions[0].text;
    };

    const imgUrl = ref('');
    const handleFileUploadSuccess = (r) => {
      console.log("qwe", r.data);
      imgUrl.value = r.data;
    };


    return {
      columns,
      onConfirm,
      fieldValue,
      showPicker,
      fieldText,
      handleFileUploadSuccess,
      imgUrl,
      showAdd,
      cascaderValueAdd,
      onFinish,
      fieldValueAdd,//地区的值
      options,
      
    };
  },
  data() {
    return {
      username: "",
      mlist: [],
      value: "",
      orderName: "",
      order: [],
    }
  },
  mounted() {
    console.log("==============", sessionStorage.getItem("name"));
    this.username = sessionStorage.getItem("name");
  },
  methods: {
    clear() {
      this.$refs.aupload.clearFiles();
    },
    fb() {
      let url = "/api/order/addOrderById";
      this.axios({
        method: "post",
        url: url,
        params: {
          username: this.username,
          detailAddress: this.order.detailAddress,
          orderName: this.order.orderName,
          orderStatus: 0,
          type: this.fieldValue,
          dh: this.order.dh,
          buyAddress: this.order.buyAddress,
          money: this.order.money,
          orderCreateTime: this.order.orderCreateTime,
          price: this.order.price,
          img: this.imgUrl,
          district: this.fieldValueAdd,
        }
      }).then(() => {
        ElMessage("发布成功！");
        this.$router.push({ path: "/main1" });
      }).catch(() => {
        ElMessage("发布失败！");
      });
    },
  }
}
</script>

<style lang="scss" scoped>
.kk {
  text-align: center;
}

.fb {
  margin-top: 20px;
  width: 80%;
}
</style>