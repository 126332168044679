<template>
    <div>
         <div class="Header"><Header/></div>
          <div class="orderdetail">
    <img :src="shenshu.img"  style="width: 100%; height: 150px;"/>
    <h5>申述内容：{{shenshu.shenshu }}</h5>
    <h5 v-if="shenshu.whoStatus==1">发起人身份：用户</h5><h5 v-if="shenshu.whoStatus==2">发起人身份：帮手</h5>
    <h5>用户名称：{{shenshu.username}}</h5>
    <h5>发起时间：{{shenshu.time}}</h5>
    <h5>帮手名称：{{shenshu.bname}}</h5>
    <h5>任务ID:{{shenshu.orderid}}</h5>
    <h5>发起人：{{shenshu.who}}</h5>
    <van-button class="wc" round type="primary" @click="wc(shenshu.shenshuid,shenshu.orderid)">完成</van-button>
  </div>
    </div>
</template>

<script>

import Header from '@/components/Header.vue';
import { ElMessage } from "element-plus";
    export default {
         components: {Header },
        data(){
            return{
                shenshuid:-1,
                shenshu:[],
                shenshuImg:"",
            }
        },
        methods:{
          getShenshuById() {  
    this.axios({  
        method: "get",  
        url: "/api/shenshu/pagenameid",  
        params: {  
            shenshuid: this.shenshuid,  
        }  
    })  
    .then((r) => {  
        this.shenshu = r.data.data[0];  
          
        // 首先输出 shenshu 对象  
        console.log("shenshu:", this.shenshu);  
          
        // 检查 shenshu 是否存在（注意：这里应该是检查对象而不是长度，因为 shenshu 是单个对象）  
        if (this.shenshu) {  
            if (this.shenshu.img) {  
                this.shenshu.img = this.$store.state.apiImg + this.shenshu.img;  
                console.log("shenshuimg:",this.shenshu.img);
            }  
            console.log("shenshu 对象已获取并可能已修改 img 属性");  
        } else {  
            console.log("没有获取到 shenshu 对象");  
        }  
    })  
    .catch((error) => {  
        console.error("获取 shenshu 对象时出错:", error);  
    });  
},
            wc(sid,orderid){
              //将申述删除，将order状态设置为：2  已完成
              this.axios({
              method: "post",
              url:"/api/shenshu/delete",
              params:{
                shenshuid:sid,
              }
             
            })
            console.log(orderid);
                  this.gx(orderid);
               
            },
            gx(orderid){
                    this.axios({
                    method:"post",
                    url:"/api/order/update",
                    params:{
                      orderStatus:2,
                      orderid:orderid,
                    }
                  }).then(
                ()=>{ 
                  ElMessage("订单完成！");
                this.$router.push({path:"/adminMain1"})
                }).catch(
                  ()=>{
                    ElMessage("订单完成失败！");
                })
           
            }
        },
        mounted(){
            this.shenshuid=this.$route.params.shenshuid;
            console.log(this.orderid);
            this.getShenshuById();

        }
    }
</script>

<style lang="scss" scoped>
.orderdetail {
  text-align: center;
  background-color: rgb(248, 248, 243);
  border: 1px solid;
//   border-radius: 20px;
  overflow: hidden;
}
.orderdetail h5{
  margin-top: 10px;
}
.wc{
    margin: 10px;
    width: 80%;
}   
</style>