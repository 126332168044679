<template>
    <div>
        <div class="Header"><Header/></div>
        <div class="kk">
            <h2>您的余额为：{{money.money}}</h2>
       
          <van-field input-align="center"  v-model="x"  placeholder="您将要充值的金额为:"  />
<van-button class="cz"  round type="primary" @click="cz()">确认充值</van-button>
<van-button class="tx"  round type="primary" @click="tx()">确认提现</van-button>
 </div>
    </div>
</template>

<script>
import { ref } from 'vue';
import { ElMessage } from "element-plus";
import Header from '@/components/Header.vue';
    export default {
             components: { Header },
        data(){
            return{
                money:[],
                username:"",
                x:'',
            }
        },
        mounted(){
             console.log("=============="+sessionStorage.getItem("name") );
             this.username=sessionStorage.getItem("name");
              this.getMoneyByUsername();
        },
        methods:{
             getMoneyByUsername(){
       let url = "/api/money/qt";
          this.axios({
            method:"get",
            url:url,
            params:{
              username:this.username
            }
          })
          .then((res) => {
          this.money= res.data.data[0];
          console.log("money:",this.money);
      });
    },
            cz(){
              console.log("余额："+this.money.money);
              console.log("金额："+this.x);
              if(this.x<=0){
                ElMessage("充值金额不能小于等于0！");
              }
              else if(!(Number.isInteger(this.x))){
                ElMessage("充值金额不能为小数！");
              }
              else{
           let url = "/api/money/updatebyname";
                  this.axios({
                    method:"post",
                    url:url,
                    params:{
                      username:this.username,
                      x:this.x,
                    }
                  }).then(
                ()=>{ 
                  ElMessage("充值成功！");
                    this.$router.push({path:"/bswode"})
                }).catch(
                  ()=>{
                    ElMessage("充值失败！");
                })
              }
          },
            tx(){
              console.log("余额："+this.money.money);
              console.log("金额："+this.x);
              if(this.money.money<=0||this.x<=0||this.x>=this.money.money){
                ElMessage("你在逗我吗，别瞎搞！");
              }
              else if(!(Number.isInteger(this.x))){
                ElMessage("提现金额不能为小数！");
              }
              else{
           let url1 = "/api/money/updatebyname1";
                  this.axios({
                    method:"post",
                    url:url1,
                    params:{
                      username:this.username,
                      x:this.x,
                    }
                  }).then(
                ()=>{ 
                //   ElMessage("付款成功！");
                this.$router.push({path:"/bswode"})
                
                }).catch(
                  ()=>{
                    // ElMessage("付款失败！");
                })
              }
          },
        }
    }
</script>

<style lang="scss" scoped>
.kk{
  text-align: center;
  

}
.kk h2{
    color: red;
}
.fb{
  margin-top: 10px;
}
::v-deep   .van-button{
  margin-top: 10px;
}
.cz{
  margin-right: 10px;
}
.tx{
  margin-left: 10px;
}
</style>