<template>
    <div>
         <div class="Header"><Header/></div>
          <div class="orderdetail">
    <img :src="orderimg"  style="width: 100%; height: 150px;"/>
    <div class="wenben">
        <h5 v-if="order.type==0">任务类型：宠物代看</h5><h5 v-if="order.type==1">任务类型：代购物品</h5><h5 v-if="order.type==2">任务类型：专业陪伴</h5><h5 v-if="order.type==3">任务类型：其他</h5>

        <h5>任务名称：{{order.orderName }}</h5>
    <h5>备注：{{order.detailAddress}}</h5>
   <h5>联系电话：{{order.dh}}</h5>
   <h5>地区：{{order.district}}</h5>
    <h5>任务地址：{{order.buyAddress}}</h5>

    <h5>时间：{{order.orderCreateTime}}</h5>
    <h5>任务金额：¥{{order.money}}</h5>
    <h5 class="sj">赏金：<span class="rmb">¥</span><span class="price">{{order.price}}</span></h5>
  </div>
</div>
    </div>
</template>

<script>
import Header from '@/components/Header.vue';
    export default {
         components: {Header },
        data(){
            return{
                orderid:-1,
                order:[],
                orderimg:"",
            }
        },
        methods:{
            getOrderById(){
                this.axios({
                    method:"get",
                    url:"/api/order/byid",
                    params:{
                        orderid:this.orderid,
                    }
                })
                .then(
                (r)=>{                  
                     this.order=r.data.data[0];
                     console.log("order:",this.order.img);
                     this.orderimg=this.$store.state.apiImg+this.order.img;
                     console.log("orderimg:",this.orderimg);
})
            },
        },
        mounted(){
            this.orderid=this.$route.params.orderid;
            console.log(this.orderid);
            this.getOrderById();

        }
    }
</script>

<style lang="scss" scoped>
.orderdetail {
  background-color: rgb(248, 248, 243);
  border: 1px solid;
//   border-radius: 20px;
  overflow: hidden;
}
.orderdetail h5 {
  margin-top: 10px;
  margin-right: 20px;
  word-break: break-word; /* 当文本超出容器宽度时换行 */
  white-space: normal; /* 默认情况下，文本不会换行，设置为 normal 允许换行 */
}
.wenben{
    margin-left: 20px;
}
.rmb{
    color: red;
    font-size: 10px;
}
.price{
    color: red;
    font-size: 16px;
}
</style>