import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'vant/lib/index.css'
import ElementPlus from 'element-plus'
import upload from 'element-plus'
import button from 'element-plus'
import dialog from 'element-plus'
import 'element-plus/dist/index.css'
import { Tabbar, TabbarItem } from 'vant';
import { Tab, Tabs } from 'vant';
import axios from 'axios'
axios.defaults.withCredentials = true
import VueAxios from 'vue-axios'
import { Form, Field,Cell, CellGroup ,ConfigProvider ,Cascader ,Picker,
    PickerGroup ,Popup , Toast,Pagination ,Uploader} from 'vant';
import { Button} from 'vant';
import { Icon } from 'vant';
import { Search,Empty,Dialog,ShareSheet , SwipeCell} from 'vant';



import { Grid, GridItem ,NavBar,Swipe, SwipeItem ,Image ,AddressEdit,} from 'vant';
createApp(App).use(store).use(router).use(VueAxios, axios)
.use(Form).use(Field).use(Cell).use(CellGroup).use(Button)
.use(ConfigProvider ).use(Cascader ).use(Picker ).use(PickerGroup ).
use(Popup).use(Icon).use(Search).use(Tabbar).use(TabbarItem).use(Tab).
use(Tabs).use(Grid).use(ShareSheet).use( SwipeCell).
use(GridItem).use(Pagination).use(NavBar).use(Swipe).use( SwipeItem).
use(Toast).use(Image).use(ElementPlus).use(AddressEdit).use(Uploader).
use(upload).use(button).use(dialog).use(Empty).use(Dialog).mount('#app')

//注册全局方法
const app = createApp(App)

app.config.globalProperties.$http = axios
// axios.defaults.withCredentials=true;cookie
app.provide('$http',axios)

