<template>
    <div>
        <div class="Header"><Header/></div>
        <van-search
  v-model="value"
  show-action
  placeholder="请输入您想查询的订单"
  @search="getOrderByStatus()"
>
<template #action>
    <div @click="getOrderByStatus()">搜索</div>
  </template>
</van-search>

<div class="vantabs">
        <van-tabs v-model:active="active"  animated @click-tab="getOrderByStatus()" >
  <van-tab title="全部" >
    <div class="qb" v-for="o in order" :key="o.orderid">
        <div class="t">
            <span   @click="orderDetail(o.orderid)">{{o.orderName}} ></span>  <h5 v-if="o.orderStatus==1">正在进行</h5><h5 v-if="o.orderStatus==2">已完成</h5><h5 v-if="o.orderStatus==3">异常</h5>
        </div>
        <div class="center">
            <img :src="o.img">
            <div class="zb">
                <h2>{{o.detailAddress}}</h2>
                <h3>帮手:{{o.bname}}</h3>
            </div>
            <h5>¥{{o.price}}</h5>
            <h6 v-if="o.orderStatus==0" @click="bj(o.orderid)">编辑</h6><h6 v-if="o.orderStatus==1" @click="lxkh(o.orderid)">联系客户</h6><h6 v-if="o.orderStatus==2" @click="lxkh(o.orderid)">联系客户</h6><h6 v-if="o.orderStatus==3" @click="lxgl(o.orderid)">联系管理</h6>
            <h4 v-if="o.orderStatus==0" @click="sc(o.orderid)">删除</h4><h4 v-if="o.orderStatus==1" @click="wc(o.orderid,o.username,o.bname,o.price)">完成</h4><h4 v-if="o.orderStatus==2" @click="ss(o.orderid)">申述</h4><h4 v-if="o.orderStatus==3" @click="wc(o.orderid,o.username,o.bname,o.price)">完成</h4>
        </div>
        
    </div>
  </van-tab>
  <van-tab title="已接单">
    <div class="qb" v-for="o in order" :key="o.orderid">
        <div class="t">
            <span  @click="orderDetail(o.orderid)">{{o.orderName}} ></span>  <h5 v-if="o.orderStatus==1">正在进行</h5><h5 v-if="o.orderStatus==2">已完成</h5><h5 v-if="o.orderStatus==3">异常</h5>
        </div>
        <div class="center">
            <img :src="o.img">
            <div class="zb">
                <h2>{{o.detailAddress}}</h2>
                <h3>帮手:{{o.bname}}</h3>
            </div>
            <h5>¥{{o.price}}</h5>
            <h6 v-if="o.orderStatus==0" @click="bj(o.orderid)">编辑</h6><h6 v-if="o.orderStatus==1" @click="lxkh(o.orderid)">联系客户</h6><h6 v-if="o.orderStatus==2" @click="lxkh(o.orderid)">联系客户</h6><h6 v-if="o.orderStatus==3" @click="lxgl(o.orderid)">联系管理</h6>
            <h4 v-if="o.orderStatus==0" @click="sc(o.orderid)">删除</h4><h4 v-if="o.orderStatus==1" @click="wc(o.orderid,o.username,o.bname,o.price)">完成</h4><h4 v-if="o.orderStatus==2" @click="ss(o.orderid)">申述</h4><h4 v-if="o.orderStatus==3" @click="wc(o.orderid,o.username,o.bname,o.price)">完成</h4>
        </div>
    </div>
  </van-tab>
  <van-tab title="已完成">
    <div class="qb" v-for="o in order" :key="o.orderid">
        <div class="t">
            <span  @click="orderDetail(o.orderid)">{{o.orderName}} ></span> <h5 v-if="o.orderStatus==1">正在进行</h5><h5 v-if="o.orderStatus==2">已完成</h5><h5 v-if="o.orderStatus==3">异常</h5>
        </div>
        <div class="center">
            <img :src="o.img">
            <div class="zb">
                <h2>{{o.detailAddress}}</h2>
                <h3>帮手:{{o.bname}}</h3>
            </div>
            <h5>¥{{o.price}}</h5>
            <h6 v-if="o.orderStatus==0" @click="bj(o.orderid)">编辑</h6><h6 v-if="o.orderStatus==1" @click="lxkh(o.orderid)">联系客户</h6><h6 v-if="o.orderStatus==2" @click="lxkh(o.orderid)">联系客户</h6><h6 v-if="o.orderStatus==3" @click="lxgl(o.orderid)">联系管理</h6>
            <h4 v-if="o.orderStatus==0" @click="sc(o.orderid)">删除</h4><h4 v-if="o.orderStatus==1" @click="wc(o.orderid,o.username,o.bname,o.price)">完成</h4><h4 v-if="o.orderStatus==2" @click="ss(o.orderid)">申述</h4><h4 v-if="o.orderStatus==3" @click="wc(o.orderid,o.username,o.bname,o.price)">完成</h4>
        </div>
    </div>
  </van-tab>
    <van-tab title="异常">
        <div class="qb" v-for="o in order" :key="o.orderid">
        <div class="t">
            <span  @click="orderDetail(o.orderid)">{{o.orderName}} ></span>  <h5 v-if="o.orderStatus==1">正在进行</h5><h5 v-if="o.orderStatus==2">已完成</h5><h5 v-if="o.orderStatus==3">异常</h5>
        </div>
        <div class="center">
            <img :src="o.img">
            <div class="zb">
                <h2>{{o.detailAddress}}</h2>
                <h3>帮手:{{o.bname}}</h3>
            </div>
            <h5>¥{{o.price}}</h5>
            <h6 v-if="o.orderStatus==0" @click="bj(o.orderid)">编辑</h6><h6 v-if="o.orderStatus==1" @click="lxkh(o.orderid)">联系客户</h6><h6 v-if="o.orderStatus==2" @click="lxkh(o.orderid)">联系客户</h6><h6 v-if="o.orderStatus==3" @click="lxgl(o.orderid)">联系管理</h6>
            <h4 v-if="o.orderStatus==0" @click="sc(o.orderid)">删除</h4><h4 v-if="o.orderStatus==1" @click="wc(o.orderid,o.username,o.bname,o.price)">完成</h4><h4 v-if="o.orderStatus==2" @click="ss(o.orderid)">申述</h4><h4 v-if="o.orderStatus==3" @click="wc(o.orderid,o.username,o.bname,o.price)">完成</h4>
        </div>
    </div>
    </van-tab>
</van-tabs>
</div>

<div class="pagination-container">  
  <van-pagination
      @click="getOrderByStatus()"
    v-model="currentPage"
    :total-items="countOrder"
    :show-page-size="3"/>
</div>
    </div>
    <div class="kb"></div>
</template>

<script>
import { ref } from 'vue';
import Header from '@/components/Header.vue';
import { ElMessage } from "element-plus";
import { showDialog,showConfirmDialog } from 'vant';
    export default {
         setup() {
    const active = ref(0);
        const currentPage = ref(1);
    return { active,currentPage };
  },
         components: {Header },
         data(){
            return{
                order:[],
                value:"",
                username:sessionStorage.getItem("name"),
                bname:"",
                lxusername:"",
                countOrder:0,
            }
         },
         mounted(){
                    this.getOrderByStatus();
    },
        methods:{
            getOrderByStatus(){
const dqy=(this.currentPage-1)*10;
      const gs=10;
  console.log("页数："+this.currentPage);
  console.log("订单状态："+this.active);
   this.axios({
              method: "get",
              url:"/api/order/bspagestatus",
             params:{
                pageNum:dqy,
                pageSize:gs,
                orderStatus:this.active,
                bname:this.username,
                orderName:this.value,
             }
            })
            .then(
                (response)=>{                  
                    this.order = response.data.data;
                    console.log(this.order);
                      if(this.order.length>0){
                        this.order.forEach(item =>{
                            if(item.img)
                        {
                            item.img =this.$store.state.apiImg + item.img
                            console.log(item.img)
                
                        }
                        });
           
                    }
                        else{
                            ElMessage("没有这么多任务啦！！");
                        }
                        console.log(this.order)
                        console.log("订单状态："+this.active);
                        if(this.active==0){
                            this.axios({
      method:"get",
      url:"/api/order/countOrder",
    })
    .then((response)=>{
      console.log(response.data.data);
      this.countOrder=response.data.data;
      console.log(this.countOrder);
    })
                        }
                });
            },

            bj(orderid){
                this.$router.push({path:"/uporder",query:{orderid:orderid}})
            },
            sc(orderid){
                showConfirmDialog({
                 
                    message:
                        '确定删除吗？',
                    })
                    .then(() => {
                        this.axios({
                    method:"post",
                    url:"/api/order/delete",
                    params:{
                        orderid:orderid,
                    }
                })
                .then(
                ()=>{                  
                    ElMessage("删除成功");
                     this.getOrderByStatus()
  
                });
                    })
                    .catch(() => {
                        // on cancel
                    });
                
              
            },
            lxkh(orderid){
                 this.axios({
                    method:"get",
                    url:"/api/order/byid",
                    params:{
                        orderid:orderid,
                    }
                })
                .then(
                (r)=>{                  
                     this.lxusername=r.data.data[0].username;
                     console.log("lxusername:",this.lxusername)


                     /////////
                     this.axios({
                    method:"get",
                    url:"/api/user/qt",
                    params:{
                        username:this.lxusername,
                    }
                })
                .then(
                (r)=>{                  
                     console.log("btel:",r.data.data[0].userTel)
                    //  alert("帮手电话:"+r.data.data[0].userTel);
                     showDialog({
                     message: "用户电话:"+r.data.data[0].userTel,
                     theme: 'round-button',
})
  
                });
                });
            },
            ss(orderid){
                this.$router.push({path:"/bsss",query:{orderid:orderid}})
            },
            lxgl(orderid){ 
                    showDialog({
                     message: "您的订单编号是："+orderid+"\n"+"管理员的电话为:18878950480",
                     theme: 'round-button',
})
            },
            wc(orderid,u,b,x){
               let url = "/api/order/update";
                  this.axios({
                    method:"post",
                    url:url,
                    params:{
                      orderStatus:2,
                      orderid: orderid,
                    }
                  }).then(
                ()=>{ 
                  ElMessage("订单完成！");
                    this.getOrderByStatus();
                    this.cz(x,b);
                    this.fk(x,u);
                }).catch(
                  ()=>{
                    ElMessage("订单完成失败！");
                })
            },
              fk(x,u){
           let url1 = "/api/money/updatebyname1";
                  this.axios({
                    method:"post",
                    url:url1,
                    params:{
                      username:u,
                      x:x,
                    }
                  }).then(
                ()=>{ 
                //   ElMessage("付款成功！");
                
                }).catch(
                  ()=>{
                    // ElMessage("付款失败！");
                })
          },
            cz(x,b){
           let url2 = "/api/money/updatebyname";
                  this.axios({
                    method:"post",
                    url:url2,
                    params:{
                      username:b,
                      x:x,
                    }
                  }).then(
                ()=>{ 
                //   ElMessage("充值成功！");
                  
                }).catch(
                  ()=>{
                    // ElMessage("充值失败！");
                })
          },
          orderDetail(id){
           this.$router.push({name:"MainDetel",params:{orderid:id}})
            },
        }
    }
</script>

<style lang="scss" scoped>
html{
   margin-bottom: 50px;
}
.t,.center{
    margin-left: 10px;
    margin-right: 10px;
    border-radius: 10px;
}
.t{
    display: flex;
      position: relative;
      margin-top: 10px;
      background-color: white;
     
}
.t span{
     margin: 10px 20px;
      
}
.t h5{
    color: rgb(224, 72, 41);
    position:absolute;
    right: 10px;
    top: -10px;
}
.center{
    background-color: white;
    display: flex;
       position: relative;
}
.center img{
    width: 100px;
    margin: 10px 20px;
    border-radius: 10px;

}
.center h6{
    border: 1px solid rgb(232, 232, 232); /* 添加边框 */
    border-radius: 10px;
    padding: 5px;
    position:absolute;
    font-size: 15px;
    right: 60px;
    top: 20px;
    color: rgb(0, 0, 0);
}
.center h4{
    border: 1px solid rgb(232, 232, 232); /* 添加边框 */
    border-radius: 10px;
    padding: 5px;
    position:absolute;
    font-size: 15px;
     right: 15px;
    top: 35px;
    color: red;
}
.zb h2{
    font-size: 15px;
      overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;//阻止换行最有效的方法
  margin-right: 50px;
}
.zb h3{
    color: rgb(221, 163, 163);
    margin-top: 25px;
}
.center h5{
position:absolute;
right: 10px;
top: -8px;
color: rgb(225, 30, 30);
}
.pagination-container {  
  position: fixed;  
  bottom: 0px;  
  left: 0;  
  width: 100%;  
  background-color: #fff; 
  text-align: center; 
  z-index: 9999; 
  box-shadow: 0 -1px 5px rgba(0, 0, 0, 0.1); 
}
.vantabs{
    background-color: rgb(249, 249, 249);
}
.kb{
  margin-top: 80px;
}
</style>