<template>
  <div>
<div class="Header"><Header/></div>

<van-search
  v-model="value"
  show-action
  placeholder="请输入您想接的类型"
  @search="getOrderByName()"
>
<template #action>
    <div @click="getOrderByName()">搜索</div>
  </template>
</van-search>
   

<!-- <van-grid :border="false" :column-num="4" square :gutter="10">
  <van-grid-item>
     <img src="../assets/帮帮logo.png"  style="width: 100%;"> 
    <span>代购物品</span>
  </van-grid-item>
  <van-grid-item>
     <img src="../assets/帮帮logo.png"  style="width: 100%;"> 
    <span>专业陪伴</span>
  </van-grid-item>
  <van-grid-item>
     <img src="../assets/帮帮logo.png"  style="width: 100%;"> 
    <span>宠物代看</span>
  </van-grid-item>
  <van-grid-item>
   
    <img src="../assets/帮帮logo.png"  style="width: 100%;">  
    <span>更多></span>
  </van-grid-item>
</van-grid> -->
<div class="topgrid">
<van-grid :border="false" :column-num="4">
  <van-grid-item @click="getOrderByOrderStatusAndType(0,0)">
    <van-image
      src="https://springboot.huangwanyv.top/bang/img/chongwu.png"
    />
    <h6 class="h66">宠物代看</h6>
  </van-grid-item>
  <van-grid-item @click="getOrderByOrderStatusAndType(1,0)">
    <van-image
      src="https://springboot.huangwanyv.top/bang/img/daigou.png"
    />
    <h6 class="h66">代购物品</h6>
  </van-grid-item>
  <van-grid-item @click="getOrderByOrderStatusAndType(2,0)">
    <van-image
      src="https://springboot.huangwanyv.top/bang/img/peihuguanli.png"
    />
    <h6 class="h66">专业陪护</h6>
  </van-grid-item>
  <van-grid-item @click="getOrderByOrderStatusAndType(3,0)">
    <van-image
      src="https://springboot.huangwanyv.top/bang/img/qita.png"
    />
    <h6 class="h66">其他帮帮</h6>
  </van-grid-item>
</van-grid>
</div>

<div class="bottomgrid">
  <van-grid  :column-num="2" square :gutter="30"    style=" --grid-item-content-background-color:#848484; margin-top: 10px;">

<van-grid-item class="list" v-for="v in mlist" :key="v.orderid" @click="detel(v.orderid)" > 
  <img :src="v.img"  style="width: 100%; height: 150px; "/>
  <div class="wenben">
        <h6 class="title">{{ v.orderName }}</h6>
        <span class="rmb">¥</span>
        <span class="price">{{ v.price }}</span>
      </div>

  </van-grid-item>

</van-grid>
</div>
<div class="kb"></div>
<div class="pagination-container">  
  <van-pagination
      @click="getOrderByName()"
      v-model="currentPage"
  :total-items="countOrder"
  :show-page-size="3"/>
</div>

<div class="Footer"><BSFooter/></div>

  </div>
</template>

<script>
import Header from '@/components/Header.vue';
import BSFooter from '@/components/BSFooter.vue';
import { ElMessage } from "element-plus";
import { ref } from "vue";
  export default {


    data(){
      return{
        username:"",
          mlist:[],
          value:"",
          orderName:"",
          countOrder:0,
      }
    },

    setup() {
    const currentPage = ref(1);
    return { currentPage };
  },
        mounted() {
    this.getOrderByName();

    this.axios({
      method:"get",
      url:"/api/order/countOrderByOrderStatus",
      params:{orderStatus:0},
    })
    .then((response)=>{
      console.log(response.data.data);
      this.countOrder=response.data.data;
      console.log(this.countOrder);
    })
          
    
  },

computed:{

        },

  methods:{
    getOrderByName(){
      const dqy=(this.currentPage-1)*10;
      const gs=10;
  console.log("页数："+this.currentPage);
   this.axios({
              method: "get",
              url:"/api/order/bspagename",
                params:{
                    pageNum:dqy,
                    pageSize:gs,
                    orderName:this.value,    
                    orderStatus:0,          }
            })
            .then((response) => {
  // 假设 response.data.data 是一个订单数组
  this.mlist = response.data.data.map(order => {
    // 创建一个新的订单对象，并修改 img 属性
    const typeTextMap = {
      0: '宠物代看',
      1: '代购物品',
      2: '专业陪护',
      3: '其他帮帮'
    };
    return {
      ...order, // 复制订单对象的所有属性
      img: this.$store.state.apiImg+order.img,// 修改 img 属性
      type:typeTextMap[order.type] || '未知类型' // 添加或覆盖 typeText 属性
    };
  });
  console.log("mlist:", this.mlist);
  if(this.mlist[0]==undefined){ 
    ElMessage("没有这么多任务啦！！");
}
})
.catch((error) => {
  // 处理请求失败的情况
  console.error("获取订单列表失败：", error);
});
this.axios({
      method:"get",
      url:"/api/order/countOrder",
    })
    .then((response)=>{
      console.log("response.data.data:"+response.data.data);
      this.countOrder=response.data.data;
      console.log(this.countOrder);
    })

    },
      detel(id){
           this.$router.push({name:"BSMainDetel",params:{orderid:id}})

},
getOrderByOrderStatusAndType(type,orderStatus){
      const dqy=(this.currentPage-1)*10;
      const gs=10;
  console.log("页数："+this.currentPage);
   this.axios({
              method: "get",
              url:"/api/order/pageByOrderStatusAndType?pageNum="+dqy+"&pageSize="+gs+"&orderName="+this.value+"&orderStatus="+orderStatus+"&type="+type,
             
            })
            .then((response) => {
  // 假设 response.data.data 是一个订单数组
  this.mlist = response.data.data.map(order => {
    // 创建一个新的订单对象，并修改 img 属性
    const typeTextMap = {
      0: '宠物代看',
      1: '代购物品',
      2: '专业陪护',
      3: '其他帮帮'
    };
    return {
      ...order, // 复制订单对象的所有属性
      img: this.$store.state.apiImg+order.img,// 修改 img 属性
      type:typeTextMap[order.type] || '未知类型' // 添加或覆盖 typeText 属性
    };
  });
  console.log("mlist:", this.mlist);
  if(this.mlist[0]==undefined){ 
    ElMessage("没有这么多任务啦！！");
}
})
.catch((error) => {
  // 处理请求失败的情况
  console.error("获取订单列表失败：", error);
});
    },

  },

    components: { BSFooter,Header },
  }
</script>

<style lang="scss" scoped>

img{
  border-radius: 9px;
}
.pagination-container {  
  position: fixed;  
  bottom: 50px;  
  left: 0;  
  width: 100%;  
  background-color: #fff; 
  text-align: center; 
  z-index: 9999; 
  box-shadow: 0 -1px 5px rgba(0, 0, 0, 0.1); 

}
.h66{
  margin-top: 3px;
}



.wenben{
  width: 100%;
  padding-left: 5px;
  
}
.wenbenType{
  text-align: center;
}
 
.ordertype{
  color: #000000;
  background-color: #94962e;
  border-radius: 4px;
  margin-top: 3px;
  font-size: 12px;
}

.price{
  color:red;
  font-size: 16px;
}
.title{
  white-space: nowrap;          // 禁止文本换行
  overflow: hidden;             // 隐藏溢出内容
  text-overflow: ellipsis;      // 可选：在内容溢出时显示省略号（...）
  margin: 3px;
  padding: 0;
  max-width: 100%;              // 确保元素不会超出其容器宽度
  box-sizing: border-box;       // 包括padding和border在内计算元素的总宽度和高度
}

.rmb{
  margin-left: 5px;
  font-size: 10px;
  color: red;

}
.kb{
  margin-top: 80px;
}
.main{
  // background-color: rgb(243, 244, 248);
  background-color: rgb(249, 249, 249);
}
// .topgrid ::v-deep   .van-grid-item__content{
//   height: 80px !important;
//   margin-top: 10px;
// }
// .van-grid-item__content {
//   padding-bottom: 10px; /* 减少底部的空白 */
// }
.topgrid ::v-deep  .van-image{
  font-size: 6px;
  width: 55px;
  height: 55px;
}
  .topgrid ::v-deep .van-grid-item {

    height: 100px; // 减少高度
    }

.bottomgrid ::v-deep .van-grid-item__content{
  padding: 0;
    border-radius: 9px;
    // width: 85%; /* 设置宽度 */
    // height: 100px; /* 设置高度 */
}




</style>