<template>
  <div>
    <div class="Header"><Header/></div>
    <van-search
  v-model="value"
  show-action
  placeholder="请输入您想查询的任务名"
  @search="getShenshu()"
>
<template #action>
    <div @click="getShenshu()">搜索</div>
  </template>
</van-search>

<van-swipe-cell  v-for="v in mlist" :key="v.userID">
  <template #left>
    <van-button square type="primary" @click="ck(v.orderid)" text="查看" />
  </template>
<h5 class="r">{{v.orderName}}</h5>
  <template #right>
    <van-button square type="danger" @click="sc(v.orderid)" text="删除" />
    <van-button square type="primary" @click="bj(v.orderid)" text="编辑" />
  </template>
</van-swipe-cell>
                  <!-- <tr>
                    <th>1</th>
                    <th>2</th>
                    <th>3</th>
                    <th>4</th>
                  </tr>
                  <tr v-for="v in mlist.data" :key="v.shenshuid">
                    <td>    {{v.shenshu}}</td>
                    <td>                 <button @click="ck(v.shenshuid)">查看</button></td>
                    <td>       <button @click="wc(v.shenshuid)">完成</button></td>
                    <td>                <button @click="shan(v.shenshuid)">删除</button></td>
                  </tr> -->
<div class="pagination-container">        
          <van-pagination
      @click="getShenshu()"
  v-model="currentPage"
  :total-items="countOrder"
  :show-page-size="3"/>
</div>

<div class="Footer"><AMFooter/></div>

  </div>
</template>

<script>
import Header from '@/components/Header.vue';
import AMFooter from '@/components/AMFooter.vue';
import { ElMessage } from "element-plus";
import { ref } from "vue";
  export default {

setup() {
    const currentPage = ref(1);
    return { currentPage };
  },

    data(){
      return{

          mlist:[],
          value:"",
          countOrder:0,
      }
    },
        mounted() {
  
          this.getShenshu();
  
          this.axios({
      method:"get",
      url:"/api/order/countOrder",
    })
    .then((response)=>{
      console.log(response.data.data);
      this.countOrder=response.data.data;
      console.log(this.countOrder);
    })
    
  },
  methods:{
    getShenshu(){
        //拿帮手
                const dqy=(this.currentPage-1)*10;
      const gs=10;
  console.log("页数："+this.currentPage);
    this.axios({
              method: "get",
              url:"/api/order/pagename",
              params:{
                pageNum:dqy,
                pageSize:gs,
                orderName:this.value,

              }
             
            })
            .then(
                (response)=>{                  
                    this.mlist = response.data.data;
                    console.log(this.mlist);
                      if(this.mlist[0]==undefined){ 
                    ElMessage("没有这么多任务啦！！");

      }
                })
                },
            ck(uname){
               this.$router.push({name:"AMCKOrderDetel",query:{orderid:uname}})
            },
            bj(uid){
               this.$router.push({path:"/amuporder",query:{orderid:uid}})
            },
            sc(uid){
               this.axios({
                    method:"post",
                    url:"/api/order/delete",
                    params:{
                      orderid:uid,
                    }
                  }).then(
                ()=>{ 
                  ElMessage("删除完成！");
                  this.getShenshu();
                }).catch(
                  ()=>{
                    ElMessage("删除失败！");
                })
            }
           
           


  },

    components: { AMFooter,Header },
  }
</script>

<style lang="scss" scoped>
.r{
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  text-align: center;

}
::v-deep   .van-button{
margin-top: -10px;
}

.pagination-container {  
  position: fixed;  
  bottom: 50px;  
  left: 0;  
  width: 100%;  
  background-color: #fff; 
  text-align: center; 
  z-index: 9999; 
  box-shadow: 0 -1px 5px rgba(0, 0, 0, 0.1); 
}
</style>